.LoginPageWrapper {
  & {

  }

  h1, h2, h3, p, a {
    color: #004AAD;
    margin-bottom: 5px;
    margin-top: 0;
  }

  Button {
    font-weight: bold;
  }

  .MuiButton-outlinedPrimary{
    border-color: #004AAD;
    color: #004AAD;

  }

  .MuiPagination-ul {
    justify-content: center;
  }

}