.SingleBookWrapper {

  &{
    margin-top:20px;
  }

  img {
    max-width: 100%;
  }

  h3 {
    color: #004AAD;

    font-size:30px;
    margin-top:0;
    margin-bottom: 5px;
  }

  p {
    color: #004AAD;
    font-size: 20px;
    margin-top:10px;
    white-space: pre-wrap;
  }

  Button {
    color: #004AAD;
    font-weight: bold;
    border-color: #004AAD;
  }

  hr {
    border-color: #004AAD;
  }

  .SingleBookHeader {
    margin-bottom: 15px;
  }
}