.NewCommentWrapper {
  button {
    color: #004AAD;
    font-weight: bold;
    border-color: #004AAD;
  }

  .MuiFormControlLabel-root {
    width: 100%;
    margin-top: 10px;
  }


}