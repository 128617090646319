@import url('https://fonts.googleapis.com/css?family=Open+Sans');


html, body {
  margin: 0;

}

* {
  font-family: 'Open Sans' !important;
}

header {
  & {
    display: flex;
    box-sizing: border-box;
    width: 100%;
    margin-bottom: 10px;
  }

  .logoSection {
    flex-basis: calc(50% - 6px);
    -webkit-box-flex: 0;
    flex-grow: 0;
    max-width: calc(50% - 6px);
    text-align: right;
    padding: 30px 60px;
    background: #919191;
    color: #fff;
    font-size: 42px;
    vertical-align: middle;
  }

  .logoSection a {
    text-decoration: none;
    color: #fff;
  }

  .logoSection a:hover {
    text-decoration: underline;
  }

  .breakSection {
    flex-basis: 12px;
    -webkit-box-flex: 0;
    flex-grow: 0;
    max-width: 12px;

  }

  .sloganSection {
    & {
      flex-basis: calc(50% - 6px);
      -webkit-box-flex: 0;
      flex-grow: 0;
      max-width: calc(50% - 6px);
      padding: 30px 60px;
      background: #004AAD;
      color: #fff;
      font-size: 20px;
      line-height: 24px;
    }

    span {
      max-width: 530px;
      width: 530px;
      display: block;
    }
  }

  @media(max-width: 786px) {
    & {
      display: block;
    }

    .breakSection {
      max-width: 0;
    }

    .logoSection a {
      font-size: 35px;
    }

    .logoSection, .sloganSection {
      width: 100%;
      max-width: 100%;
      flex-basis: 100%;
      padding: 30px;
      display: block;
      box-sizing: border-box;
      text-align: center;
    }

    .sloganSection span, .logoSection span {
      min-width: 0;
      width: 100%;
    }

    .sloganSection span {
      font-size: 16px;
    }
  }
}
#content {
  min-height: 55vh;
}
footer {
  & {
    margin-top: 20px;
    padding-top: 30px;
    padding-bottom: 20px;
    background: #A6A6A6;
  }

  .footerWrapper {
    & > div:nth-child(1) {
      color: #fff;
      text-align: center;
      font-size: 40px;
    }

    & > div:nth-child(2) {
      @media(max-width: 768px) {
        & {
          text-align: center;
        }
      }
    }

    & > div:nth-child(3) {
      text-align: center;
    }

    a {
      color: #000;
      text-decoration: none;
      font-size: 18px;
    }

    .footer-link {
      font-size: 15px;
      font-weight: bold;
      color:#004AAD;

    }

    a:hover {
      text-decoration: underline;
    }

    img {
      max-width: 100%;
    }
  }
}

.PageHeader {
  & {
    padding: 8px 0;
    width: 100%;
    background: #004AAD;
    margin-bottom: 10px;
  }

  h2 {
    color: #fff;
    font-size: 16px;
  }
}
