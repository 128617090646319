.BookList {
  .PageHeader {

    .mementoForm, .MuiSelect-select, .MuiAutocomplete-inputRoot {
      background : #fff;
      border-radius: 0;
    }

    button {
      background: #fff;
      line-height: 2.4;
      border-radius: 0;
    }

    .MuiInputBase-input, .MuiSelect-select {
      //min-height:0;
      //height:1em;
    }

  }

  .BookListElements {

    & {
      margin-bottom: 25px;
    }
    a {
      text-decoration: none;
    }
    .BookListElementWrapper {


      & {
        padding:8px;
        cursor: pointer;
      }

      &:hover, &:active{
        box-shadow: 0px 3px 2px -2px rgba(0, 74, 173,0.5), 0px 2px 2px 0px rgba(0, 74, 173,0.44), 0px 2px 4px 0px rgba(0, 74, 173,0.32);
      }

      img {
        width: 100%;
        max-width: 150px;
      }

      span {
        color: #004AAD;
        font-size: 28px;

      }
    }
  }

  .MuiPagination-ul {
    justify-content: center;
  }
}

@media(max-width: 768px) {
  .BookListElementWrapper {
    text-align: center;
  }
}