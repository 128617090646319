.BookCommentWrapper {
  & {
    padding: 10px;
    margin-bottom: 10px;
    width: 100%;
  }

  img {
    max-width: 100%;
    width: 300px !important;
    float: left;
    margin-right: 20px;
    margin-bottom: 20px;
  }
}